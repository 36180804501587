@@include('../node_modules/jquery/dist/jquery.min.js')
@@include('../node_modules/mustache/mustache.min.js')
@@include('../node_modules/masonry-layout/dist/masonry.pkgd.min.js')
@@include('../node_modules/jquery-validation/dist/jquery.validate.min.js')
@@include('../node_modules/slick-carousel/slick/slick.min.js')
@@include('../node_modules/particles.js/particles.js')
@@include('../node_modules/jquery-file-upload/js/jquery.uploadfile.min.js');

@@include('../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js');

