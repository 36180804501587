$(function(){

  $('.js-warn-sm').css('opacity', '0').css('z-index', '-1');

  $('.js-select-market').on('click', function(){

    let array = [];
    let value = 0;

    $('.js-select-market').each(function(){
      if($(this).prop('checked')){
        array[array.length] = $(this).attr('name');
      }
    });

    $('.js-select-market-full').text(separationRanks(value));
    $('.js-check-market').removeClass('active');

    function getMarketCount(number){
      let el = $('.js-check-market[data-check-market="'+number+'"]');
      el.addClass('active');
      return el.data("value-market");
    }

    switch(array.length){
      case 0:
        $('.js-warn-count').text("1 исследование бесплатно").css('text-transform', 'none').css('font-variant', 'small-caps');
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
        $('.js-select-market-full').text(0);
        break;
      case 3:
        $('.js-warn-count').text("2 исследования бесплатно").css('text-transform', 'none').css('font-variant', 'small-caps');
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
        $('.js-select-market-full').text(separationRanks(getMarketCount(5)));
        break;
      case 4:
        $('.js-warn-count').text("1 исследование бесплатно").css('text-transform', 'none').css('font-variant', 'small-caps');
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
        $('.js-select-market-full').text(separationRanks(getMarketCount(5)));
        break;
      case 6:
        $('.js-warn-count').text("6 исследований бесплатно").css('text-transform', 'none').css('font-variant', 'small-caps');
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
        $('.js-select-market-full').text(separationRanks(getMarketCount(12)));
        break;
      case 7:
        $('.js-warn-count').text("5 исследований бесплатно").css('text-transform', 'none').css('font-variant', 'small-caps');
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
        $('.js-select-market-full').text(separationRanks(getMarketCount(12)));
        break;
      case 8:
        $('.js-warn-count').text("4 исследования бесплатно").css('text-transform', 'none').css('font-variant', 'small-caps');
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
        $('.js-select-market-full').text(separationRanks(getMarketCount(12)));
        break;
      case 9:
        $('.js-warn-count').text("3 исследования бесплатно").css('text-transform', 'none').css('font-variant', 'small-caps');
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
        $('.js-select-market-full').text(separationRanks(getMarketCount(12)));
        break;
      case 10:
        $('.js-warn-count').text("2 исследования бесплатно").css('text-transform', 'none').css('font-variant', 'small-caps');
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
        $('.js-select-market-full').text(separationRanks(getMarketCount(12)));
        break;
      case 11:
        $('.js-warn-count').text("1 исследование бесплатно").css('text-transform', 'none').css('font-variant', 'small-caps');
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
        $('.js-select-market-full').text(separationRanks(getMarketCount(12)));
        break;
      case 1:
      case 2:
      case 5:
      case 12:
        $('.js-select-market-buy').removeClass('no-event');
        $('.js-warn-sm').css('opacity', '0').css('z-index', '-1');
        $('.js-select-market-full').text(separationRanks(getMarketCount(array.length)));
        break;
      default:
        $('.js-warn-count').text("");
        $('.js-select-market-buy').addClass('no-event');
        $('.js-warn-sm').css('opacity', '1').css('z-index', '1');
    }

    let stringify = JSON.stringify(array);
    let selected = stringify;
    let price = $('.js-check-market.active').data("value-market");
    let params = {
      selected: selected,
      price: price
    }
    $('.js-select-market-submit').attr("data-params", JSON.stringify(params));

  });

});
