$(function(){
  if($(".js-upload-file").length){
    $(".js-upload-file").uploadFile({
      url: "upload.php",
      fileName: "arb-upload__file-img",
      previewWidth: "auto",
      autoSubmit: false,
      showError: false,
      showPreview: true,
      showCancel: true,
      cancelStr: "Удалить файл"
    });
  }
});
