$(() => {
	let dataFilter = {
		theme:'',
		speaker:'',
		city:'',
		type:'',
		free:'',
		online:'',
	};
	let items = $('.js-table-item');
	setDataFilter(dataFilter);

	$('.js-web-filter-select, .js-web-filter-checkbox').change(()=>{
		setDataFilter(dataFilter); // установка значений
		drawItems(dataFilter,items); // отрисовка элементов таблицы
		drawFeedbackForm();
		drawBtnReset();
	});

	$('.js-filter__reset').mouseup(()=>{ 
		resetFilters(dataFilter);
		drawItems(dataFilter,items);
		drawFeedbackForm();
		drawBtnReset();
	});
});

// Отрисовка элементов
function drawItems(dataFilter,items){
	setItemsShowFilter(dataFilter,items);
	for (let item of items){
		if($(item).attr('data-show')=='true'){
			$(item).fadeIn();
		} else {
			$(item).fadeOut();
		}
	}
}

// Выставление флага Show отфильтрованным элементам
function setItemsShowFilter(dataFilter,items){
	for (let item of items) {
		if(isItemFilter(dataFilter,item)){
			$(item).attr('data-show', 'true');
		} else {
			$(item).attr('data-show', 'false');
		}
	}
}
// ------------end-setItemsShowFilter---------------

// проверка строки таблицы на параметры фильтра
function isItemFilter(dataFilter,elem){
	let isItem = true,

		theme = $(elem).attr('data-theme'),
		speaker = $(elem).attr('data-speaker'),
		city = $(elem).attr('data-city'),
		type = $(elem).attr('data-type'),
		free = $(elem).attr('data-free'),
		online = $(elem).attr('data-online');

		if(dataFilter.theme != 'all'){
			if(theme != dataFilter.theme){
				isItem = false;
			}
		}
		if(dataFilter.speaker != 'all'){
			if(speaker != dataFilter.speaker){
				isItem = false;
			}
		}
		if(dataFilter.city != 'all'){
			if(city != dataFilter.city){
				isItem = false;
			}
		}
		if(dataFilter.type != 'all'){
			if(type != dataFilter.type){
				isItem = false;
			}
		}

		if(dataFilter.free){
			if(free != 'Y'){
				isItem = false;
			}
		}
		if(dataFilter.online){
			if(online != 'Y'){
				isItem = false;
			}
		}

	return isItem;
}
// ------------end-isItemFilter---------------

// установка значений фильтра
function setDataFilter(dataFilter) {
	let selects = $('.js-web-filter-select');
	let checkboxs = $('.js-web-filter-checkbox');

	for (let sel of selects) {
		let filterName = $(sel).attr('data-filter-name');

		switch (filterName) {
			case 'theme':
				dataFilter.theme = $(sel).val();
				break;

			case 'speaker':
				dataFilter.speaker = $(sel).val();
				break;

			case 'city':
				dataFilter.city = $(sel).val();
				break;

			case 'type':
				dataFilter.type = $(sel).val();
				break;

			default:
				break;
		}
	}

	for (let check of checkboxs) {
		let filterName = $(check).attr('data-filter-name');

		switch (filterName) {
			case 'free':
				dataFilter.free = $(check).prop("checked");
				break;

			case 'online':
				dataFilter.online = $(check).prop("checked");
				break;

			default:
				break;
		}
	}
}
// ------------end-setDataFilter---------------

// проверка на дефолтные настройки фильтров
function isDefaultFilter() {
	let selects = $('.js-web-filter-select');
	let checkboxs = $('.js-web-filter-checkbox');

	let isDefault = true;

	for (let sel of selects) {
		if ($(sel).val() != 'all') {
			isDefault = false;
		}
	}

	for (let check of checkboxs) {
		if ($(check).prop("checked")) {
			isDefault = false;
		}
	}

	return isDefault;
}
// ------------end-isDefaultFilter---------------

// проверка на количество отфильтрованных семинаров
function getShowItems() {
	let items = $('.js-table-item');
	let counter = 0;

	for (let item of items) {
		if ($(item).attr('data-show') == 'true') {
			counter++;
		}
	}

	return counter;
}
// ------------end-getShowItems---------------

// Отрисовка формы обратной связи при пустой таблице
function drawFeedbackForm(){
	if(getShowItems()==0){
		$('.js-table__info').show(300);
	} else {
		$('.js-table__info').hide();
	}
}
// ------------end-drawFeedbackForm---------------

// Отрисовка кнопки сброса фильтров
function drawBtnReset(){
	if(!isDefaultFilter()){
		$('.js-filter__reset').fadeTo(300,1);
	} else {
		$('.js-filter__reset').hide();
	}
}
// ------------end-drawBtnReset---------------

// Сброс настроек фильтров
function resetFilters(dataFilter){
	let selects = $('.js-web-filter-select');
    let checkboxs = $('.js-web-filter-checkbox');

    for(let sel of selects){
      $(sel).val('all');
    }

    for(let check of checkboxs){
      $(check).prop("checked", false);
	}
	setDataFilter(dataFilter);
}
// ------------end-getResetFilters---------------