// magnificPopup
$(document).ready(function() {
	// $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
	//   // disableOn: 700,
	//   type: 'iframe',
	//   mainClass: 'mfp-fade',
	//   removalDelay: 160,
	//   preloader: false,

	//   fixedContentPos: true,
	//   iframe: {
	//     patterns: {
	//       youtube: {
	//         index: 'youtube.com/',
	//         id: function (url) {
	//           var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
	//           if (!m || !m[1]) return null;
	//           return m[1];
	//         },
	//         src: '//www.youtube.com/embed/%id%?autoplay=1'
	//       },
	//       vimeo: {
	//         index: 'vimeo.com/',
	//         id: function (url) {
	//           var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
	//           if (!m || !m[5]) return null;
	//           return m[5];
	//         },
	//         src: '//player.vimeo.com/video/%id%?autoplay=1'
	//       }
	//     }
	//   }
	// });

	// let $videos = $('.js-youtube');
	let $playBtn = $('.js-youtube__play');

	$playBtn.mouseup(function (e) {
		let $video = $(this).closest('.js-youtube');
		let id = $video.data('id');

		$video.html(createIframe(id));
	});

	$(window).mouseup(function (e){
		stopAllVideos();
	});
});
//------------------


function addEvent(iframe, event) {
  const video = iframe;
  video[event] = () => {
    video.contentWindow.postMessage(`{"event":"command","func":"${event}"}`, '*');
  };
}

function generateURL(id) {
  const query = '?rel=0&showinfo=0&autoplay=1&enablejsapi=1';
  return `https://www.youtube.com/embed/${id}${query}`;
}

function createIframe(id) {
  const iframe = document.createElement('iframe');

  iframe.setAttribute('allowfullscreen', '');
  iframe.setAttribute('allow', 'autoplay');
  iframe.setAttribute('src', generateURL(id));
  iframe.classList.add('youtube__media');
  iframe.classList.add('js-youtube-api');

  addEvent(iframe, 'playVideo');
  addEvent(iframe, 'pauseVideo');
  addEvent(iframe, 'stopVideo');

  return iframe;
}

// function setupVideo(video) {
//   const link = video.querySelector('.js-youtube--link');
//   const button = video.querySelector('.js-youtube--button');
//   const id = video.getAttribute('data-id');

//   button.addEventListener('click', () => {
//     const iframe = createIframe(id);

//     link.remove();
//     // button.remove();
//     // почему-то при удалении кнопки при клике не на первый слайд слайд откатывается к началу
//     video.appendChild(iframe);

//     stopAllVideos();
//   });

//   link.removeAttribute('href');
//   link.removeAttribute('target');
// }

function initYouTube() {
  const videos = document.querySelectorAll('.js-youtube');

  videos.forEach(video => {
    // setupVideo(video);
  });
}

// initYouTube();

function stopAllVideos() {
  const videos = document.querySelectorAll('.js-youtube iframe');

  videos.forEach(video => {
    video.stopVideo();
  });
}
