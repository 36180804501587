$(function() {
  const square = document.querySelector('.js-square-init');

  if (!square) return;

  square.querySelectorAll('.nojs').forEach((item) => {
    item.classList.remove('nojs');
    item.classList.add('js-square-item');
  });

  $('.js-square-more').each((i, el) => {
    $(el).detach().appendTo('.js-square-hidden');
    $(el).attr('data-view', 'hidden').css("top", 'auto').show();

    $(el).find('.js-close').on('click', () => {
      $(el).attr("data-view", 'hidden');
    });

    //закрытие по клику мимо
    $(document).mouseup(function (e) {
      let block = $(e.target).closest('.js-square-hidden');
      if(!block.hasClass('js-square-hidden')){
        $(el).attr("data-view", 'hidden');
      }
    });
  });

  $('.js-square-item').on('click', (e) => {
    const data = $(e.currentTarget).attr('data-item');
    $('.js-square-more:not([data-more="'+data+'"])').attr('data-view', 'hidden');

    setTimeout(() => {
      $('.js-square-more[data-more="'+data+'"]').attr('data-view', 'visible');
    }, 300);
  });
});
