$(function(){
  if($('.js-tabs-block-button').length && $('.js-tabs-block-section').length){
    let tabs = $('.js-tabs-block-button');
    $('.js-tabs-block-section').removeClass('active');

    for(let tab of tabs){
      if($(tab).hasClass('active')){
        let data = $(tab).data('tabs-block');
        $('.js-tabs-block-section[data-tabs-block="' + data + '"]').addClass('active');
      }
    }

    $('.js-tabs-block-button').on('click',function(){
      activeTabResearch(this);

      if($('.js-tabs-block-section[data-tabs-block="research"]').hasClass('active')){
        $('.js-research-slider').slick('setPosition');
      }
    });
  }

  function activeTabResearch(el) {
    let then = $(el),
          data = then.data('tabs-block');
      $('.js-tabs-block-button').not(this).removeClass('active');
      $('.js-tabs-block-section').removeClass('active');
      then.addClass('active');
      $('.js-tabs-block-section[data-tabs-block="' + data + '"]').addClass('active');
  }
});
