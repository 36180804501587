$(() => {
	$('.js-news-filter-button').on('mouseup', e => {
		const button = $(e.currentTarget);
		const filter = button.attr('data-filter');
		window.open(`?direction=${filter}`, '_self');
	});

	$('.js-news-filter-select').on('change', e => {
		const select = $(e.currentTarget);
		const val = select.val();
		window.open(`?tags=${val}`, '_self');
	});
});