$(function(){
  if($('.arb-tablet__hexagon--popup').length){
    $('.arb-tablet__hexagon--popup').hover(function(){
      $('.arb-tablet__hexagon--popup').not(this).removeClass('active');
      $(this).addClass('active');
    }, function(){
      $(this).removeClass('active');
    });
  }
});
