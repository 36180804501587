$(function(){
  if($('.js-market-item').length){
    $('.js-market-item').each(function(){
      let el = $(this);
      let id = el.data("id");
      let name = el.data("name");
      let params = {
        id: id,
        name: name
      }
      el.find('.js-popup-window-show').attr("data-params", JSON.stringify(params));
    });
  }
});
