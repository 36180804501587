$(function(){
  if($('.js-strategy-diagram-toggle').length && $('.js-strategy-diagram').length){
    $('.js-strategy-diagram-toggle').on('click', function(){
      let btn = $(this),
          text = btn.find('.btn__text'),
          diagram = $('.js-strategy-diagram');
      if(diagram.hasClass('b-chunk')){
        text.text('Свернуть');
        diagram.removeClass('b-chunk');
      }else{
        text.text('Показать еще');
        diagram.addClass('b-chunk');
      }
    });

    $('.js-strategy-diagram').find('.js-strategy-diagram-checkpoint').each(function(){
      if ( $(this).find('.hover').length ) {
        let hover = $(this).find('.js-strategy-diagram-description');
        let toggle = $(this).find('.hover');
        toggle.hide().removeClass('hover');
        hover.hover(function(){
          toggle.stop().slideToggle(400, function(){
            toggle.stop(true);
          });
        });
      }
    });

  }
});
