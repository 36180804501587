$(function() {
  $('.js-slider-carousel--presentations').each((i, slider) => {
    if ($(slider).children().length > 4) {
      $(slider).slick({
        slidesToShow: 4,
        arrows: false,
        dots: true,
        dotsClass: "arb-dots slick-dots",
        transformEnabled: false
      });
    }
  });

  // banner
  $('.js-banner-slider').each(function() {
    if ($(this).children().length > 1) {
      $(this).slick({
        // arrows: $(this).parent().hasClass('banner-strategy'),
        arrows: true,
        dots: true,
        dotsClass: "arb-dots arb-dots--white slick-dots"
      });
    }
  });

  // clients
  $('.js-clients__slider').each(function() {
    if ($(this).children().length > 1) {
      $(this).slick({
        arrows: false,
        dots: true,
        dotsClass: "arb-dots slick-dots"
      });
    }
  });

  // content
  $('.js-content-carousel__slider').each(function() {
    if ($(this).children().length > 1) {
      $(this).slick({
        arrows: true,
        dots: true,
        dotsClass: "arb-dots slick-dots",
        focusOnSelect: true
      });
    }
  });

  // research
  $('.js-research-slider').each(function() {
    $(this).slick({
      // adaptiveHeight:true,
      arrows: false,
      slidesToShow: 3,
      autoplay: true,
      autoplaySpeed: 200000,
      focusOnSelect: true
    });
  });

  $('.js-research-slider-reverse').each(function() {
    $(this).slick({
      arrows: false,
      slidesToShow: 3,
      autoplay: true,
      autoplaySpeed: 200000,
      focusOnSelect: true,
      rtl: true
    });
  });

  // strategies
  $('.js-leading-slider').each(function() {
    if ($(this).children().length > 1) {
      $(this).slick({
        arrows: false,
        dots: true,
        dotsClass: "arb-dots slick-dots"
      });
    }
  });

  $('.js-leading-slider-for').each(function() {
    if ($(this).children().length > 1) {
      $(this).slick({
        arrows: false,
        dots: true,
        dotsClass: "arb-dots slick-dots",
        asNavFor: '.js-leading-slider-nav'
      });
    }
  });

  $('.js-leading-slider-nav').each(function() {
    if ($(this).children().length > 1) {
      $(this).slick({
        asNavFor: '.js-leading-slider-for',
        fade: true,
        arrows: false
      });
    }
  });

  // video-carousel
  $('.js-video-carousel__slider').each(function() {
    const slidesCount = $(this).children().length;
    let slidesToShow = 3;
    let dots = true;

    if (slidesCount === 1) {
      slidesToShow = 1;
      dots = false;
    }

    if ([2, 3].indexOf(slidesCount) !== -1) {
      $(this).append($(this).html());
      initYouTube();
    }

    $(this).slick({
      centerMode: true,
      arrows: false,
      dots: dots,
      dotsClass: "arb-dots slick-dots",
      slidesToShow: slidesToShow,
      centerPadding: '0px',
      focusOnSelect: true,
      // variableWidth: true,
      adaptiveHeight: true,
    }).on('afterChange', function() {
      stopAllVideos();
    });
  });

  // main
  $('.js-market-carousel').each(function() {
    if ($(this).children().length > 1) {
      $(this).slick({
        dots: true,
        dotsClass: "arb-dots slick-dots",
        slidesToShow: 4,
        slidesToScroll: 4
      });
    }
  });
});
