$(function() {
  if ($('.js-choose-tech-item').length && $('.js-choose-tech-hover').length) {
    // $('.js-choose-tech-item').hover(
    //   function() {
    //     const hash = $(this).attr('id');
    //     if (hash) window.location.hash = hash;

    //     $('.js-choose-tech-item').not(this).find('.js-choose-tech-hover').stop().fadeOut(400);
    //     $('.js-choose-tech-item').not(this).find('.arb-choose-tech__img-block').removeClass('b-active');

    //     $(this).find('.js-choose-tech-hover').stop().fadeIn(400, function() {
    //       $(this).stop(true);
    //     });

    //     $(this).find('.arb-choose-tech__img-block').addClass('b-active');
    //   },

    //   () => {
    //     $('.js-choose-tech-item').find('.js-choose-tech-hover').stop().fadeOut(400);
    //     $('.js-choose-tech-item').find('.arb-choose-tech__img-block').removeClass('b-active');
    //   }
    // );

    $(document).mouseup(function (e) {
      let block = $(e.target).closest('.js-choose-tech-item');
      // console.log($(e.target).closest('.js-choose-tech-hover'));

        if($(e.target).hasClass('js-relation-item')){
          $('.js-choose-tech-item').find('.js-choose-tech-hover').fadeOut(0);
          block.find('.js-choose-tech-hover').fadeIn(400);
        } else if(!$(e.target).closest('.js-choose-tech-hover').hasClass('js-choose-tech-hover')) {
          $('.js-choose-tech-item').find('.js-choose-tech-hover').fadeOut(0);
        } else if($(e.target).hasClass('js-arb-choose-tech__close')){
          $('.js-choose-tech-item').find('.js-choose-tech-hover').fadeOut(0);
        }
    });
  }
});
