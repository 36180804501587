$(function() {
  if ($('.js-popup-window-show').length && $('.js-popup-window').length) {
    $(document).on('click', '.js-popup-window-show', (e) => {
      let form = $('.js-popup-window').find('form').addClass('js-show-form-ajax');
      let params = $(e.currentTarget).data();
      formAjax('.js-show-form-ajax', params);
      $('.js-popup-window').show();
      $('body').addClass('popup-open');
    });

    $('.js-popup-window-overlay, .js-popup-window-close').on('click', (e) => {
      const popup = $(e.currentTarget).parents('.js-popup-window');
      popup.find('form').removeClass('js-show-form-ajax');
      popup.hide().find('input').each((i, input) => {
        $(input).val('');
      });
      $('body').removeClass('popup-open');
    });

    $('.js-popup-window-ok').on('click', (e) => {
      const button = $(e.currentTarget);
      const form = button.parents('form');
      const popup = button.parents('.js-popup-window');
      validatePopupWindow(form, popup);
    });
  }
});

function validatePopupWindow(form, popup) {
  const name = form.find('input[name="user-name"]');
  const phone = form.find('input[name="user-phone"]');

  name.on('input', () => {
    name.removeClass('error');
    phone.removeClass('error');
  });
  phone.on('input', () => {
    name.removeClass('error');
    phone.removeClass('error');
  });

  if (name.val() === '') {
    name.addClass('error');
  } else if (phone.val() === '') {
    phone.addClass('error');
  } else {
    form.submit();
    popup.hide().find('input').each((i, input) => {
      $(input).val('');
    });
    $('.js-popup-thanks').show();
    $('.js-popup-thanks').on('click', '.js-popup-thanks-ok', (e) => {
      const button = $(e.currentTarget);
      button.parents('.js-popup-thanks').hide();
    });
  }
}
