$(function(){
  if($('.js-banner-hover').length && $('.js-banner-toggle').length){
    $('.js-banner-hover').hover(
      function() {
        $(this).find('.js-banner-toggle').stop().slideDown(80);
      },
      function() {
        $(this).find('.js-banner-toggle').stop().slideUp(80);
      }
    );
  }
  $('.js-banner-study-submit').on('click', function(){
    let el = $(this);
    let banner = el.parents('.js-banner-study');
    let name = banner.data("name");
    let price = banner.data("price");
    let params = {
      name: name,
      price: price
    }
    el.attr("data-params", JSON.stringify(params));
  });

  $('.js-slider-banner-button[data-tablet]').on('click', function(e){
    e.preventDefault();
    let el = $(this).data('tablet'),
        toggle = $('.js-tablet-toggle[data-tablet="'+el+'"]'),
        destination = toggle.offset().top - 80;
    $('html:not(:animated)').animate({scrollTop: destination}, 2000, 'swing', function(){
      toggle.trigger('click');
    });
  });
});
