$(function() {
  if ($('.js-stage-item').length && $('.js-stage-hover').length) {
    // $('.js-stage-item').hover(function() {
    //   $(this).find('.js-stage-hover').stop().fadeToggle(400, function() {
    //     $(this).stop(true);
    //   });
    // });

    $(document).mouseup(function (e) {

      let block = $(e.target).closest('.js-stage-item');
      // console.log($(e.target).closest('.js-stage-hover'));

        if($(e.target).hasClass('js-relation-item')){
          $('.js-stage-item').find('.js-stage-hover').fadeOut(0);
          block.find('.js-stage-hover').fadeIn(400);
        } else if(!$(e.target).closest('.js-stage-hover').hasClass('js-stage-hover')) {
          $('.js-stage-item').find('.js-stage-hover').fadeOut(0);
        } else if($(e.target).hasClass('js-arb-stage__close')){
          $('.js-stage-item').find('.js-stage-hover').fadeOut(0);
        }
    });
  }
});
