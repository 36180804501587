$(function(){
  if($('.js-seminars-case').length && $('.js-seminars-speaker').length){
    $('.js-seminars-case').hover(function(){
      let then = $(this),
          report = then.data('report'),
          background = then.data('bg'),
          speaker = then.parents('section').find('.js-seminars-speaker');
      speaker.removeAttr('style').removeClass('hidden');
      if(background) speaker.css('background-image', 'url('+background+')');
      if(report){
        speaker.find('.arb-seminars__report').text(report);
      }else{
        speaker.addClass('hidden');
      }
      speaker.toggleClass('active');
    });
  }
});
