$(() => {
  if($('.js-tablet-toggle').length && $('.js-tablet').length){
    $('.js-tablet-toggle').on('click', e => {
      e.preventDefault();
      let self = $(e.currentTarget),
          data = self.data('tablet'),
          getTablet = () => $('.js-tablet[data-tablet="'+data+'"]'),
          destination = () => $('.js-tablet-block-scroll').offset().top - 80;

      window.location.hash = data;
      if ( self.hasClass('active') ) {
        self.removeClass('active');
        getTablet().fadeOut();
      } else {
        $('.js-tablet-toggle').not(self).removeClass('active');
        self.addClass('active');
        $('.js-tablet').not(getTablet()).fadeOut(400, () => {
          getTablet().fadeIn(400, () => {
            $('html:not(:animated)').animate(
              {scrollTop: destination()},
              2000,
              'swing'
            );
          });
        });
      }
    });
  };
});
