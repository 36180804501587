$(function() {
  $('.js-load-news').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget);
    const url = $this.attr('href');
    let count = $this.parent().attr("data-count-news");

    $this.addClass('b-disabled');

    $.ajax({
      url,
      global: true,
      data: {
        'PAGEN_1': count,
        'AJAX': 'Y'
      },
      success: data => {
        const $grid = $('.js-load-news-container');
        let html = '';

        $(data).find('.arb-news__item').each((i, content) => {
          const $content = $(content);
          const $social = $content.find('.arb-news__social');

          if ($social.length === 0) {
            setTimeout(() => {
              $grid.append($content).masonry('appended', $content).masonry('reloadItems');

              $this.removeClass('b-disabled');
            }, 1000);
          }
        });

        let $buttonTmp = $(data).find('.js-load-news');

        if ($buttonTmp.length) {
          let buttonCount = $buttonTmp.parent().attr('data-count-news');
          $this.parent().attr("data-count-news", buttonCount);
        } else {
          $this.parent().hide();
        }

        $grid.masonry('reloadItems');
      }
    });
  });

  //Masonry - новости в разделе финансы
  // setTimeout(()=>{
  //   $('.arb-news__masonry').masonry({
  //     // columnWidth: 200,
  //     itemSelector: '.arb-news__item',
  //   });
  // },1000);

});
