$(function() {
  arbHover('.js-why-list-item', '.js-why-desc');

  function arbHover(hoverClass, openClass) {
    const hover = $(hoverClass);
    const open = hover.find(openClass);
    hover.removeClass('hover');

    if (hover.length && open.length) {
      hover.on('click', (e) => {
        e.preventDefault();
        const el = $(e.currentTarget);

        if (el.hasClass('b-open')) {
          el.removeClass('b-open');

          hover.find(openClass).stop().slideUp(400, function() {
            $(this).stop(true);
          });
        } else {
          el.addClass('b-open');

          hover.find(openClass).not(el).stop().slideUp(400, function() {
            $(this).stop(true);
          });

          el.find(openClass).stop().slideDown(400, function() {
            $(this).stop(true);
          });
        }
      });
    }
  }
});
