$(function(){
  if($('.js-counter-download').length){
    $('.js-counter-download').on('click', function(){
      let el = $(this);
      let item = el.parents('.js-counter-download-ajax');
      let amount = item.find('.js-counter-download-amount');
      let path = el.data("counter-download-ajax");
      let id = el.data("id");
      amount.text((parseInt(amount.text()) + 1) + " раз");
      $.ajax({
        url: path,
        type: "POST",
        data: {
          id: id
        }
      });
    });
  }
  if($('.js-tiles-universe').length && $('.js-tiles-universe-item').length && $('.js-tiles-universe-toggle').length){
    $('.js-tiles-universe-toggle').on('click', function(){
      let self = $(this);
      let $root = self.parents('.js-tiles-universe');
      let $items = $root.find('.js-tiles-universe-item');
      let textButton = self.find('.btn__text');
      self.toggleClass('show');
      $items.each(function(i, item){
        if (i >= 4 ) {
          if (self.hasClass('show')) {
            $(item).show();
            textButton.text('Скрыть');
          } else {
            $(item).hide();
            textButton.text('Показать ещё');
          }
        }
      });
    });
  }
});
