// Плавный скролл
$('[data-scroll-to]').on('click', function() {
  const header = $('.header__true');
  const padding = (header.hasClass('ad')) ? $('.header__true').height() : 80;
  let elementClick = $(this).data('scroll-to'),
      destination  = $('[data-scroll-id="'+elementClick+'"]').offset().top - (padding || 80);

  $('html:not(:animated)').animate({scrollTop: destination}, 2000, 'swing');
});

// Чтобы не кидало наверх
$(document).on('click', '[href="#"]', function(e) {
  if ( !$(this).data('scroll-to') ) e.preventDefault();
});

// Разделение разрядов числа пробелами
function separationRanks(number) {
  let num = (String(number)) ? String(number).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : number;
  return num;
}

$(function() {
  $('.js-price').each(function(){
    let then = $(this),
        text = then.text();
    then.text(separationRanks(text));
  });

  $(document).on('click', '.js-events-toggle', function() {
      $(this).hide();
      var container = $(this).parents('.js-events-container');
      container.find('.js-events-item').show();
  });
});
