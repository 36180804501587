$(function(){
  if($('.js-issues-list').length){
    let el = $('.js-issues');
    let list = el.find('.js-issues-list');
    let ajax = list.attr("data-action");

    $.getJSON(ajax, function(data){

      let counter = el.find('.js-issues-counter');
      let template = el.find('.js-issues-templates');
      let backpedal = el.find('.js-issues-backpedal');
      let html = template.html();
      let items = '.js-issues-list-item';

      let arResult = data;
      let len = arResult.length;
      let level = 0;
      let save = [];

      backpedal.on('click', function(){
        if (save[save.length - 1]) {
          delete save.splice(save.length - 1, 1);
        }
        if (save[save.length - 1]) {
          onClick({ parent_id: save[save.length - 1].id });
          backpedal.text(save[save.length - 1].text)
        } else {
          onClick({ level: 1 });
        }
      });

      function onClick(objParams) {

        let arParams = [];

        function addElem(i) {
          arParams.push(arResult[i]);
          level = arResult[i].depth_level;
          if (level == 1) backpedal.hide();
        }

        for (let i = 0; i < len; i++) {
          if (objParams.level) {
            if (arResult[i].depth_level == objParams.level) {
              addElem(i)
            }
          } else if (objParams.parent_id) {
            if (arResult[i].parent_id == objParams.parent_id) {
              addElem(i)
            }
          }
        }

        let mustacheInfo = Mustache.render(html, arParams);
        let render = $(mustacheInfo);

        counter.text(level);

        render.find(items).on('click', function(){

          let tmpData = {
            id: $(this).data("id"),
            text: $(this).text()
          }
          save.push(tmpData);
          onClick({ parent_id: tmpData.id });
          backpedal
            .text(tmpData.text)
            .show();

        });

        list.html(render);
      }

      onClick({ level: 1 });

    });

  }
});
