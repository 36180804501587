$(function(){
  if($('.js-menu-full').length && $('.js-menu-button').length){
    $('.js-menu-button').on("click", function(e){
      e.preventDefault();
      $(this).toggleClass('active');
      $('.js-menu-full').stop().slideToggle(400, function(){
        $(this).stop(true);
      });
    });
  }
  if($('.js-header-search').length && $('.js-menu-search').length){
    $('.js-header-search').on("click", function(e){
      e.preventDefault();
      $(this).toggleClass('active');
      $('.js-menu-search').stop().slideToggle(400, function(){
        $(this).stop(true);
      });
    });
  }
  if($('.js-menu-item').length && $('.js-submenu').length){
    $('.js-menu-item').each(function(){
      // $(this).hover(function(){
      //   $(this).find('.js-submenu').stop().slideToggle(400, function(){
      //     $(this).stop(true);
      //   });
      // });
      $(this).hover(function () {
          // over
          $(this).find('.js-submenu').stop().slideDown(400);
          
        }, function () {
          // out
          $(this).find('.js-submenu').stop().slideUp(400);
        }
      );
    });
  }
});
