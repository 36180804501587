$(function() {
  if ($('.js-subscription-form').length && $('.js-subscription-submit').length) {
    $('.js-subscription-form').each((i, form) => {
      $(form).find('.js-subscription-submit').on('click', (e) => {
        const button = $(e.currentTarget);
        const input = $(form).find('[name="email"]');
        const email = input.val();

        if (validateEmail(email)) {
          $(form).submit();
          input.removeClass('error').val('');
        } else {
          input.addClass('error');
        }
      });

      $(form).find('input[name="email"]').on('input', (e) => {
        $(e.currentTarget).removeClass('error');
      });

      $(form).unbind("submit").on("submit", (e) => {
        e.preventDefault();
        const form = $(e.currentTarget);

        const url = form.attr('action');
        const type = form.attr('method');
        const data = form.find('input').filter((i, el) => {
          return $(el).val() !== '';
        }).serialize();

        $.ajax({ url, type, data });
      });
    });
  }
});

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
