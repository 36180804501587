$(function(){
  if($('#there-is-strategy').length){
    $('#there-is-strategy').on('change', function(){
      let y = $('.js-checkbox-box-section[data-checkbox-box="yes"]'),
          n = $('.js-checkbox-box-section[data-checkbox-box="no"]');
      if($(this).prop('checked')){
        y.slideDown();
        n.slideUp();
      }else{
        n.slideDown();
        y.slideUp();
      }
    });
  }
});
