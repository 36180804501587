$(function(){
  if($('.js-solutions-card').length &&
     $('.js-solutions-suit').length &&
     $('.js-solutions-get').length){
    $('.js-solutions-card').on('click', function(){
      let href = $(this).find('[data-scroll-to]').attr('href');
      $('[data-scroll-id]').not(href).find('.js-solutions-card').removeClass('b-active');
      $(href).find('.js-solutions-card').addClass('b-active');

      for(let elemCard of $('.js-solutions-card')){
        if($(elemCard).hasClass('b-active')){
          $(elemCard).find('.js-solutions-suit').stop().slideDown(300);
        } else {
          $(elemCard).find('.js-solutions-suit').stop().slideUp(300);
        }
      }
    });
  }
});
