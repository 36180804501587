$(function() {
  window.formAjax = (context, dataParams) => {
    function getDataAttr(_data, _default) {
      if (dataParams !== undefined && dataParams[_data] !== undefined) {
        return dataParams[_data];
      }

      if (form.attr(_data)) {
        return form.attr(_data);
      }

      return _default;
    }

    let form = $(context);
    let action = getDataAttr("action", "/ajax/");
    let method = getDataAttr("method", "post");
    let submit = form.find('[type="submit"]') || form.find('.js-form-type-submit');
    let params = [];
    let serialize = undefined;
    let ajaxParams = undefined;

    if (dataParams !== undefined) {
      for (let i in dataParams) {
        // "data-ajax-params".replace(/\bdata-ajax-/g, '')
        if( i.indexOf("ajax") == 0 ) {
          if (ajaxParams === undefined) ajaxParams = [];
          ajaxParams[i] = dataParams[i]
        }
      }
    }

    submit.unbind("click").on('click', (e) => {
      e.preventDefault();
      form.submit();
    });

    form.unbind("submit").on("submit", (e) => {
      e.preventDefault();

      serialize = form.find('input').filter((i, el) => {
        return $(el).val() !== '';
      }).serialize();

      params.push(serialize);

      if (dataParams !== undefined && dataParams.params !== undefined && Object.keys(dataParams.params).length) {
        let arParams = [];

        for (let i in dataParams.params) {
          arParams.push(i + "=" + dataParams.params[i]);
        }

        params.push(arParams.join("&"));
      }

      if (ajaxParams !== undefined && Object.keys(ajaxParams).length) {
        let arParams = [];

        for (let i in ajaxParams) {
          arParams.push(i + "=" + ajaxParams[i]);
        }

        params.push(arParams.join("&"));
      }

      $.ajax({
        url: action,
        type: method,
        data: params.join("&"),
        success: (res) => {
          if (typeof res === 'string') res = JSON.parse(res);
          if (res.STATUS === "OK") ym(24445127, 'reachGoal', 'zapros');
        }
      });
    });
  }

  $('form:not(.js-form-no-ajax):not(.js-form-custom)').each((i, form) => {
    formAjax(form);
  });
});
