$(function(){
  setTimeout(function() {
    if ( window.location.hash ) {
      $('html:not(:animated),body:not(:animated)').animate({scrollTop: $(window.location.hash).offset().top - 80}, 2000, 'swing');

      // сделанно по-подругому в banner--12.js
      // if ( ['#update', '#improve', '#new'].includes(window.location.hash) && $('.js-solutions-card').length ) {
      //   $(window.location.hash).find('.js-solutions-card').addClass('b-active');
      //   console.log(window.location.hash);
      // }

      if ( $('.js-choose-tech-item'+window.location.hash).length ) {
        $('.js-choose-tech-item'+window.location.hash).mouseenter().find('.arb-choose-tech__img-block').addClass('b-active');
      }

      if ( $('.js-tablet-toggle'+window.location.hash).length ) {
        $('.js-tablet-toggle'+window.location.hash).click();
      }
    }
  }, 0);
});
