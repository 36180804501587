$(function(){
  if($('.js-research-button').length && $('.js-research-section').length){
    // activeResearch('.js-research-button');

    $('.js-research-button').on('click', function(){
      activeResearch(this);
    });
  }

  function activeResearch(el){
    let then = $(el),
          data = then.data('research-card'),
          active = then.parents('section').find('.js-research-button[data-research-card="' + data + '"]'),
          buttons = then.parents('section').find('.js-research-button'),
          sections = then.parents('section').find('.js-research-section');
      buttons.not(then).removeClass('active');
      sections.not(then).removeClass('active');
      active.addClass('active');
      then.parents('section').find('.js-research-section[data-research-card="' + data + '"]').addClass('active');
  }
});
