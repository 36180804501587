// переход из баннера в решения и активация нужного при клике на кнопку-якорь
$(function () {
    $('.js-banner-slider').mouseup(function (elem) {
        let btn = $(elem.target).parents('.btn');
        let link = btn.attr('href');

        if (link && link.length > 1) {
            link = link.split('/').splice(-1,1).join();
            // console.log(link);

            // очистка карточки если активна
            for (let card of $('.js-solutions-card')) {
                if ($(card).hasClass('b-active')) {
                    $(card).removeClass('b-active');
                }
            }

            // установка активного состояние по якорю
            $(link).find('.js-solutions-card').addClass('b-active');

            // раскрытие активной карточки и скрытие неактивной
            for (let card of $('.js-solutions-card')) {
                if ($(card).hasClass('b-active')) {
                    $(card).find('.js-solutions-suit').stop().slideDown(300);
                } else {
                    $(card).find('.js-solutions-suit').stop().slideUp(300);
                }
            }
        }
    });
});