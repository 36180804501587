$(function() {
  if (window.localStorage) {
    if (window.localStorage.getItem('js-adblock') != $('.js-adblock').data("watch")) {
      $('.js-adblock').parent().addClass('ad').parent().addClass('ad-p');
      $('.arb-web-filter').addClass('b-top-header');
    }

    $('.js-adblock-close').on('click', function(){
      $('.js-adblock').parent().removeClass('ad').parent().removeClass('ad-p');
      $('.arb-web-filter').removeClass('b-top-header');
      window.localStorage.setItem('js-adblock', $('.js-adblock').data("watch"));
    });
  }
});
