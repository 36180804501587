$(function() {
  $('.js-input-editable').on('keyup', (e) => {
    const $this = $(e.currentTarget);
    const name = $this.data('name');
    const text = $this.text();

    $this.removeClass('error');
    $('.js-validate-form').find('[data-name="'+name+'"]').val(text);
  });

  $('.js-input-editable').on('click', (e) => {
    const self = e.currentTarget;
    const placeholder = self.getAttribute('data-placeholder');

    if (placeholder === self.innerHTML) {
      self.innerHTML = '';
    }
  });

  $('.js-input-editable').on('blur', (e) => {
    const self = e.currentTarget;
    const text = self.textContent;

    if (text === '') {
      const placeholder = self.getAttribute('data-placeholder');
      self.textContent = placeholder;
    }
  });

  $('.js-popup-thanks-submit').on('click', () => {
    $('.js-validate-form').submit();

    $('.js-input-editable').each((i, el) => {
      $(el).removeClass('error');
      $(el).text($(el).data('placeholder'));
    });

    $('.js-validate-form input.error').each((i, el) => {
      const name = $(el).data('name');
      $('.js-input-editable[data-name="'+name+'"]').addClass('error');
    });
  });

  $('.js-popup-thanks, .js-popup-thanks-ok').on('click', () => {
    $('.js-popup-thanks').hide();
    $('body').removeClass('popup-open');
  });

  $.validator.addMethod('regexp', function(value, element, params) {
    var expression = new RegExp(params);
    return this.optional(element) || expression.test(value);
  });

  $('.js-validate-form').validate({
    rules: {
      name: {
        required: () => {
          let reqName = $('.js-validate-form').find('input[data-name="name"]').attr('data-req');
          return reqName=='true';
        },
        minlength: 4,
      },
      email: {
        // required: !!$('.js-tell-everyone .js-input-editable[data-name="email"]').length,
        required: () => {
          let reqName = $('.js-validate-form').find('input[data-name="email"]').attr('data-req');
          return reqName=='true';
        },
        email: true,
        minlength: 4,
      },
      phone: {
        required: () => {
          let reqName = $('.js-validate-form').find('input[data-name="phone"]').attr('data-req');
          return reqName=='true';
        },
        // number: true,
        regexp: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/g,
        minlength: 4,
      },
      city: {
        required: () => {
          let reqName = $('.js-validate-form').find('input[data-name="city"]').attr('data-req');
          return reqName=='true';
        },
        minlength: 4,
      }
    },

    submitHandler: (form, e) => {
      e.preventDefault();

      const serialize = $(form).find('input').filter((i, el) => {
        return $(el).val() !== '';
      }).serialize();

      $('.js-popup-thanks').show();
      console.log($('.js-popup-thanks'));
      $('body').addClass('popup-open');

      $.ajax({
        url: form.action,
        type: form.method,
        data: serialize,
        success: (res) => {
          if (typeof res === 'string') res = JSON.parse(res);
          if (res.STATUS === "OK") ym(24445127, 'reachGoal', 'zapros');
        }
      });
    },
  });
});
